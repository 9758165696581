import React from "react";
import { Link } from "gatsby";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import SEO from "../components/seo";

class Page extends React.Component {
  render() {
    const css = {
      subTitle: "text-2xl mt-16 mb-8",
      sectionTitle: "text-xl mt-8 mb-4",
      link: "text-action-500",
      subText: "text-gray-600",
    };

    const Paragraph = ({ children }) => <p className="my-4">{children}</p>;

    return (
      <Base>
        <SEO title="Données personnelles" />
        <div className="pb-32">
          <div className="py-16 bg-gray-100">
            <Container>
              <h1 className="text-3xl font-bold">
                Politique de protection des données personnelles du site
                omnidoc.fr
              </h1>
            </Container>
          </div>
          <Container>
            <div className="max-w-2xl">
              <Paragraph>
                <span>
                  Assurer la sécurité et la confidentialité des données
                  personnelles de ses utilisateurs (médecins) et de leurs
                  patients est une priorité absolue pour Omnidoc. Omnidoc
                  respecte l’ensemble des dispositions réglementaires et
                  législatives françaises et européennes relatives à la
                  protection des données personnelles.
                  <br />
                  <br />
                  Omnidoc se conforme également aux règles déontologiques
                  édictées par les instances ordinales représentatives des
                  professions médicales présentes sur son service.
                  <br />
                  <br />
                  Omnidoc applique une politique extrêmement stricte pour
                  garantir la protection des données personnelles de santé de
                  ses utilisateurs :
                  <br />
                  <br />
                  <li>
                    Chaque utilisateur d’Omnidoc reste maître de ses données.
                    Omnidoc n’en dispose pas librement.
                  </li>
                  <li>
                    Elles sont traitées de manière transparente, confidentielle
                    et sécurisée.
                  </li>
                  <li>
                    Omnidoc est engagé dans une démarche continue de protection
                    des données de ses utilisateurs, en conformité avec la Loi
                    Informatique et Libertés du 6 janvier 1978 modifiée
                    (ci-après « LIL ») et du Règlement (UE) général sur la
                    protection des données du 27 avril 2016 (ci-après « RGPD »).
                  </li>
                  <li>
                    Omnidoc dispose d’un DPO (Data Protection Officer) et d’un
                    directeur de la sécurité.
                  </li>
                  <li>
                    Les données personnelles de santé des utilisateurs sont
                    hébergées par des prestataires ayant reçu l’agrément HDS
                    (Hébergeur de Données de Santé) validé par l’ANS
                    (Agence du Numérique en Santé).
                  </li>
                </span>
              </Paragraph>
              <h2 className={css.subTitle}>Objet de la présente politique</h2>
              <Paragraph>
                <span className={css.subText}>
                  Omnidoc souhaite vous informer par l’intermédiaire de la
                  présente politique de la manière dont nous protégeons vos
                  données à caractère personnel traitées via le site
                  https://www.omnidoc.fr/.
                </span>
                <br />
                <br />
                La présente politique décrit la manière dont Omnidoc et les
                professionnels de santé utilisateurs de ses services traitent
                les données à caractère personnel des visiteurs et des
                utilisateurs (ci-après le/les « Utilisateur(s) ») lors de leur
                navigation sur le site www.omnidoc.fr (ci-après le « Site »).
                <br />
                <br />
                Certaines Données Personnelles de l’Utilisateur doivent être
                considérées comme des données à caractère personnelles de santé,
                et sont ci-après dénommées « Données Personnelles de Santé ».
                <br />
                <br />
                Cette Politique peut être modifiée, complétée ou mise à jour
                afin notamment de se conformer à toute évolution légale,
                réglementaire, jurisprudentielle et technique. Cependant, les
                Données Personnelles de l’Utilisateur seront toujours traitées
                conformément à la politique en vigueur au moment de leur
                collecte, sauf si une prescription légale impérative venait à en
                disposer autrement et serait d'application rétroactive.
                <br />
                <br />
                Cette politique fait partie intégrante des{" "}
                <Link className={css.link} to="/cgu">
                  Conditions Générales d’Utilisation
                </Link>{" "}
                du Site.
              </Paragraph>

              <h2 className={css.subTitle}>
                Identité des responsables de traitement
              </h2>
              <Paragraph>
                <span className={css.subText}>
                  Rappel légal : Le responsable du traitement est, au sens du RGPD,
                  la personne qui détermine les moyens et les finalités du
                  traitement. Le sous-traitant est une personne traitant des
                  données à caractère personnel pour le compte du responsable du
                  traitement. Il agit sous l’autorité du responsable du
                  traitement et sur instruction de celui-ci.
                </span>
                <br />
                <br />
                En fonction des Données à caractère personnel traitées,
                Omnidoc est susceptible d’agir en qualité de Responsable de traitement
                ou de Sous-traitant pour le compte des Acteurs de santé.
                <br />
                <br />
                Omnidoc est Responsable de traitement des Données à caractère personnel
                des Utilisateurs recueillies dans le cadre de la création et gestion du
                Compte Utilisateur, de leur navigation sur le Site et de leur utilisation de
                la Plateforme Omnidoc.
                <br />
                <br />
                Les Acteurs de santé, Abonnés des Services, sont Responsables de
                traitement des Données à caractère personnel recueillies dans
                le cadre de toute téléexpertise. Omnidoc intervient alors en
                tant que Sous-traitant.
                <br />
                <br />
                Qu’elle soit responsable de traitement ou sous-traitant, Omnidoc
                prend les mesures propres à assurer la protection et la
                confidentialité des informations nominatives qu’elle détient ou
                qu’elle traite dans le respect des dispositions de la LIL et du
                RGPD. Pour plus d’information concernant les services proposés
                par Omnidoc vous pouvez vous référer aux{" "}
                <Link className={css.link} to="/cgu">
                  Conditions Générales d’Utilisation
                </Link>{" "}
                du Site.
              </Paragraph>
              <h2 className={css.subTitle}>Finalité des données collectées</h2>
              <Paragraph>
                <span className={css.subText}>
                  Lors de la réalisation de téléexpertises, Omnidoc collecte les
                  informations d’identification du patient (nom, prénom, numéro
                  de sécurité sociale, régime d’assurance maladie), et les
                  informations du dossier médical nécessaires à l’établissement
                  du diagnostic par le médecin requis. Omnidoc rappelle au
                  préalable l’obligation du médecin requérant de demander
                  l’autorisation auprès du patient pour la réalisation d’une
                  téléexpertise et l’utilisation du site Omnidoc.
                </span>
                <br />
                <br />
                Le recueil de ces Données Personnelles a pour base légale :
                <br />
                <br />
                <li>
                  L’intérêt légitime d’Omnidoc à assurer la meilleure qualité de
                  ses services, à fournir à ses Utilisateurs le meilleur suivi
                  possible de leurs rendez-vous et de leurs parcours de soins,
                  et à améliorer le fonctionnement de son Site et de son
                  Application;
                </li>
                <li>
                  L’intérêt légitime d’Omnidoc à produire des données
                  statistiques relatives à l'impact d’Omnidoc sur l’activité des
                  Professionnels de santé afin de communiquer sur son outil et
                  améliorer ses services;. Les données des Utilisateurs
                  d’Omnidoc et de leurs patients sont principalement traitées
                  pour :
                </li>
                <li>
                  Permettre leur navigation sur le Site et permettre leur
                  utilisation de l’Application
                </li>
                <li>
                  Permettre la réalisation optimale de téléexpertise, c’est à
                  dire l’échange d’avis médicaux concernant la prise en charge
                  d’un patient
                </li>
                <br />
                Le caractère obligatoire ou facultatif des données personnelles
                demandées et les éventuelles conséquences d'un défaut de réponse
                à l’égard des Utilisateurs d’Omnidoc sont précisés lors de
                leur(s) collecte(s).
              </Paragraph>
              <h2 className={css.subTitle}>Types de données traitées</h2>
              <Paragraph>
                Omnidoc est susceptible de traiter, en tant que Sous-traitant,
                pour permettre la réalisation de téléexpertises, tout ou partie
                des données suivantes :
                <br />
                <br />
                <li>Numéro de téléphone, adresse email</li>
                <li>Mot de passe</li>
                <li>Numéro de facturation (Numéro AM ou FINESS)</li>
                <li>Logiciel de facturation</li>
                <li>
                  Nom (et nom de naissance), prénom, date de naissance, régime
                  d’assurance maladie du patient
                </li>
                <li>
                  Destinataire de la demande de téléexpertise (médecin
                  individuel, réseau ou spécialité)
                </li>
                <li>
                  Contenu de la demande, dont les pièces jointes attachées à la
                  demande (photos, documents, textes etc..)
                </li>
                <li>
                  Contenu des échanges de la téléexpertise, incluant la réponse
                  du médecin requis
                </li>
              </Paragraph>
              <h2 className={css.subTitle}>
                Non communication des données personnelles
              </h2>
              <Paragraph>
                <span className={css.subText}>
                  Les Données Personnelles de l’Utilisateur ne seront pas
                  transmises à des acteurs commerciaux ou publicitaires.
                </span>
                <br />
                <br />
                Les Données Personnelles de l’Utilisateur peuvent être traitées
                par des sous-traitants (prestataires de services), dans le
                respect absolu du principe énoncé ci-dessus, exclusivement afin
                de réaliser les finalités de la présente politique.
                <br />
                <br />
                Dans la limite de leurs attributions respectives et pour les
                finalités rappelées ci-dessus, les principales personnes
                susceptibles d’avoir accès aux données des Utilisateurs (hors
                Données de Santé) d’Omnidoc sont principalement les agents de
                notre service client.
                <br />
                <br />
                De plus, afin de respecter les dispositions du Code de la santé
                publique concernant les Données Personnelles de Santé, Omnidoc a
                recours à des Hébergeurs Données de Santé (dits “HDS”)
                bénéficiant de l’agrément délivré par le MInistère de la Santé.
                <br />
                <br />
                Omnidoc recourt également aux prestations fournies par plusieurs
                sociétés spécialisées (mailing, sms) dont la
                liste peut être communiquée aux personnes concernées sur demande
                adressée à contact_rgpd@omnidoc.fr. Aucune Donnée Personnelle de
                Santé ne leur est communiquée.
              </Paragraph>
              <h2 className={css.subTitle}>
                Durée de conservation des données
              </h2>
              <Paragraph>
                Nous conservons vos données uniquement le temps nécessaire pour
                les finalités poursuivies, conformément aux prescriptions
                légales.
              </Paragraph>
              <h2 className={css.subTitle}>Les droits des utilisateurs</h2>

              <Paragraph>
                Chaque fois que Omnidoc traite des Données Personnelles, Omnidoc
                prend toutes les mesures raisonnables pour s’assurer de
                l’exactitude et de la pertinence des Données Personnelles au
                regard des finalités pour lesquelles Omnidoc les traite.
                Conformément à la réglementation européenne en vigueur, les
                Utilisateurs d’Omnidoc disposent des droits suivants :
                <br />
                <br />
                <li>
                  Droit d'accès (article 15 RGPD) et de rectification (article
                  16 RGPD), de mise à jour, de complétude des données des
                  Utilisateurs
                </li>
                <li>
                  Droit de verrouillage ou d’effacement des données des
                  Utilisateurs à caractère personnel (article 17 du RGPD),
                  lorsqu’elles sont inexactes, incomplètes, équivoques,
                  périmées, ou dont la collecte, l'utilisation, la communication
                  ou la conservation est interdite
                </li>
                <li>
                  Droit de retirer à tout moment un consentement (article 13-2c
                  RGPD)
                </li>
                <li>
                  Droit à la limitation du traitement des données des
                  Utilisateurs (article 18 RGPD)
                </li>
                <li>
                  Droit d’opposition au traitement des données des Utilisateurs
                  (article 21 RGPD)
                </li>
                <li>
                  Droit à la portabilité des données que les Utilisateurs auront
                  fournies, lorsque ces données font l’objet de traitements
                  automatisés fondés sur leur consentement ou sur un contrat
                  (article 20 RGPD)
                </li>
                <li>
                  Droit de définir le sort des données des Utilisateurs après
                  leur mort et de choisir à qui Omnidoc devra communiquer (ou
                  non) ses données à un tiers qu’ils aura préalablement désigné
                </li>
                Si l’Utilisateur souhaite savoir comment Omnidoc utilise ses
                Données Personnelles, demander à les rectifier ou s’oppose à
                leur traitement, l’Utilisateur peut contacter Omnidoc par écrit
                à l’adresse suivante : Omnidoc – 12 rue Anselme, 93400
                Saint-Ouen ou contact_rgpd@omnidoc.fr.
                <br />
                <br />
                Dans ce cas, l’Utilisateur doit indiquer les Données
                Personnelles qu’il souhaiterait qu’Omnidoc corrige, mette à jour
                ou supprime, en s’identifiant de manière précise avec une copie
                d’une pièce d’identité (carte d’identité ou passeport). Les
                demandes de suppression de Données Personnelles seront soumises
                aux obligations qui sont imposées à Omnidoc par la loi,
                notamment en matière de conservation ou d’archivage des
                documents. Enfin, les Utilisateurs de Omnidoc peuvent déposer
                une réclamation auprès des autorités de contrôle, et notamment
                de la CNIL (https://www.cnil.fr/fr/plaintes).
              </Paragraph>

              <h2 className={css.subTitle}>Sécurité</h2>
              <Paragraph>
                Omnidoc met en œuvre toutes les mesures techniques et
                organisationnelles afin d’assurer la sécurité des traitements de
                données à caractère personnel et la confidentialité de Données
                Personnelles.
                <br />
                <br />A ce titre, Omnidoc prend toutes les précautions utiles,
                au regard de la nature des données et des risques présentés par
                le traitement, afin de préserver la sécurité des données et,
                notamment, d’empêcher qu’elles soient déformées, endommagées, ou
                que des tiers non autorisés y aient accès (protection physique
                des locaux, procédés d’authentification avec accès personnel et
                sécurisé via des identifiants et mots de passe confidentiels,
                journalisation des connexions, chiffrement de certaines
                données…).
              </Paragraph>
              <h2 className={css.subTitle}>
                Nous contacter - coordonnées du DPO
              </h2>

              <Paragraph>
                Si l’Utilisateur a des questions ou des réclamations concernant
                le respect par Omnidoc de la présente Politique, ou si
                l’Utilisateur souhaite faire part à Omnidoc de recommandations
                ou des commentaires visant à améliorer la qualité de la présente
                politique, l’Utilisateur peut contacter Omnidoc par écrit à
                l’adresse suivante : Omnidoc – 12 rue Anselme, 93400 Saint-Ouen
                ou contact_rgpd@omnidoc.fr.
              </Paragraph>
            </div>
            <div className="mt-12"><Paragraph>Dernière mise à jour : 23 avril 2021.</Paragraph></div>
          </Container>
        </div>
      </Base>
    );
  }
}

export default Page;
